/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * React Component for Weavy Chat
 * @component
 *
 * @module WeavyChat
 *
 * @description
 * A React component that handles the integration with the Weavy chat system. It initializes the chat, handles user details, conversations, messages, and related functionalities.
 *
 */

import React, {useEffect, useRef, useState} from 'react'
import {WeavyClient, WeavyProvider, MessengerProvider} from '@weavy/uikit-react'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import axios from 'axios'
import {useSelector, useDispatch} from 'react-redux'
import {Flexed, Loading, Spacer} from '../styled/shared'
import {toast} from 'react-toastify'
import useRouter from '../components/common/UserRouterHook'
import {api} from '../components/api/callAxios'
import ReportUserModal from '../components/modals/ReportUserModal'
import BlockUserModal from '../components/modals/BlockUserModal'
import PhoneModal from '../components/modals/PhoneModal'
import {palette} from '../styled/colors'
import {MdCall} from 'react-icons/md'
import {FaSms} from 'react-icons/fa'
import {BsChat, BsFlag, BsXLg, BsPlus} from 'react-icons/bs'
import {IoEllipsisVerticalSharp} from 'react-icons/io5'
import {verifyJSON} from '../constants/commonFunctions'
import {MdKeyboardArrowUp, MdKeyboardArrowDown} from 'react-icons/md'
import SafetyTipsModal from '../components/modals/SafetyTipsModal'
import moment from 'moment'
import Pusher from 'pusher-js'
import {loginUserDetailsAction} from '../actions/authActions'
import _ from 'lodash'
import {BiMobile} from 'react-icons/bi'
import {doGenerateAiResponse} from '../components/api/apis'

function WeavyChat() {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const [loading, setLoading] = useState(false)
	const [userToken, setUserToken] = useState('')
	const environment = process.env.REACT_APP_PUBLIC_WEAVY_URL
	const apiKey = process.env.REACT_APP_PUBLIC_WEAVY_APIKEY
	const clientRef = React.useRef<WeavyClient | null>(null)
	const [conversactionId, setConversationId] = useState<any>(null)
	const router: any = useRouter()
	let _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	let id = router?.query?.id ? router?.query?.id : null
	const userRef: any = useRef()
	const [user, setUser] = useState<any>()
	const [reportUserModal, setReportBlockUser] = useState(false)
	const [blockUserModal, setBlockUser] = useState(false)
	const [isDropdownOpen, setDropdownOpen] = useState(false)
	const [callModel, setCallModel] = useState({show: false, isCall: false})
	const dropdownRef: any = useRef(null)
	const [addDetails, setAddDetails] = useState<any>()
	const [activeUserId, setActiveUserId] = useState<any>()
	const [chatUsers, setChatUsers] = useState<any>([])
	const [adId, setAdId] = useState<any>(null)
	const [chatId, setChatId] = useState<any>(null)
	const [safetyTipsModel, setSafetyTipsModel] = useState(false)
	const [customerLoading, setCustomerLoading] = useState(false)
	const [closeChat, setCloseChat] = useState<any>(false)
	const [otherUserAd, setOtherUserAd] = useState<any>(false)
	const [blockUsers, setBlockUsers] = useState<any>([])
	const [viewPhoneUsers, setViewPhoneUsers] = useState<any>([])
	const [allConversations, setAllConversations] = useState<any>([])
	const [messages, setMessages] = useState<any>([])
	const [chatMessage, setChatMessage] = useState('')
	const [selectedTab, setSelectedTab] = useState('questions')
	const [isExpanded, setExpanded] = useState(true)
	const [offeredPrice, setOfferedPrice] = useState<any>(0)
	const [appId, setAppId] = useState('')
	const [loginUserData, setLoginUserData] = useState<any>()
	const [chatLoad, setChatLoad] = useState(true)
	const fileInputRef: any = useRef(null)
	const [dummyMessages, setDummyMessages] = useState<any[]>([])

	const handleExpandClick = () => {
		setExpanded(!isExpanded)
	}
	const handleTabClick = (tab: any) => {
		setSelectedTab(tab)
	}

	let _dispatch = useDispatch()

	useEffect(() => {
		if (_loginUserDetails) {
			getAllBlockUsers()
			initializeWeavyApp()
			setLoginUserData(_loginUserDetails)
			id && getUserDetails(id)
			if (router?.query?.addId) {
				getPostDetails(router?.query?.addId)
				getPhoneViewStatus(router?.query?.addId)
			}
		}
	}, [_loginUserDetails])

	const updateUserDetails = async (ids: any, islogin: any, user: any, isUpdate?: any) => {
		const uniqueData =
			user?.conversationId &&
			user?.conversationId.filter((obj: any, index: any, array: any) => {
				return array.findIndex((item: any) => item?.conversationId === obj?.conversationId) === index
			})
		if (isUpdate) {
			const updatedConversationId = user?.conversationId && user?.conversationId.filter((obj: any) => obj?.conversationId != ids?.conversationId)
		}

		await api
			.put(`/customers/${user?.id}`, {
				conversationId: user?.conversationId ? [...uniqueData, ids] : [ids],
				firstname: user?.firstname,
				lastname: user?.lastname,
				isChat: true
			})
			.then((res) => {
				if (res?.data && islogin) {
					_dispatch(loginUserDetailsAction(res.data.data))
				}
			})
			.catch((e) => {})
	}

	const initializeWeavyApp = async () => {
		await api
			.get(`customers/${_loginUserDetails?.id}?page=1&pageSize=15`)
			.then(async (response) => setLoginUserData(response?.data?.customer?.customer))
			.catch(function (error) {
				console.log(error)
			})
		const dbUserResponse: any = _loginUserDetails
		const name = _loginUserDetails.firstname + ' ' + _loginUserDetails.lastname

		const headers = {
			Authorization: `Bearer ${apiKey}`,
			'Content-Type': 'application/json'
		}

		const url = `${environment}/api/apps/init`

		const data = {
			app: {
				uid: _loginUserDetails?.id + 'a',
				name: name,
				type: 'chat',
				display_name: name
			},
			user: {
				uid: _loginUserDetails?.id + 'a',
				name: name,
				display_name: name
			}
		}

		try {
			setLoading(true)
			const response = await axios.post(url, data, {headers})

			let userUrl = `${environment}/api/users/${response.data?.uid}/tokens`

			await axios.post(`${environment}/api/apps/${response?.data?.id}/subscribe`, {}, {headers})

			const userResponse = await axios.post(
				userUrl,
				{
					uid: _loginUserDetails?.id + 'a',
					name: name,
					expires_in: 14400
				},
				{headers}
			)

			await axios
				.put(
					`${environment}/api/users/${response.data?.uid}`,
					{
						name: name,
						uid: response.data?.uid,
						phone_number: dbUserResponse?.phone,
						picture: dbUserResponse?.photo ? process.env.REACT_APP_IMAGE_URL + dbUserResponse?.photo : '/images/icons/icon_Profile.png',
						presence: 'active',
						metadata: {email: dbUserResponse?.email, userId: dbUserResponse?.id, isCreator: true}
					},
					{headers}
				)
				.then((res) => {
					setAppId(res.data?.id)
				})
				.catch((err) => {
					setLoading(false)
					if (err?.response?.data?.detail) {
						toast.error(err?.response?.data?.detail)
					}
				})
			setUserToken(userResponse.data.access_token)
			const updateHeader = {
				Authorization: `Bearer ${userResponse.data.access_token}`,
				'Content-Type': 'application/json'
			}

			setUserToken(userResponse.data.access_token)
			if (id) {
				const url = `${environment}/api/conversations`
				const headers = {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${userResponse.data.access_token}`
				}

				let _userDetails = await axios
					.get(`${environment}/api/users/${id}_${router?.query?.addId}_${_loginUserDetails?.id}`, {headers})
					.then((res: any) => res)
					.catch((err) => err)

				let _customer =
					id &&
					(await api
						.get(`customers/${id}?page=1&pageSize=15&isUsePusher=true`)
						.then(async (response) => response?.data?.customer?.customer)
						.catch(function (error) {
							console.log(error)
						}))
				const _name = _customer.fullname

				if (_userDetails?.response?.status == 404 || _userDetails?.data?.metadata?.add_id != adId) {
					const headers = {
						Authorization: `Bearer ${apiKey}`,
						'Content-Type': 'application/json'
					}

					await axios
						.post(
							`${environment}/api/users`,
							{
								uid: `${id}_${router?.query?.addId}_${_loginUserDetails?.id}`,
								name: _name,
								expires_in: 14400
							},
							{headers}
						)
						.then((res) => {})
						.catch((err) => {
							console.log(err)
						})
				}

				const _headers = {
					Authorization: `Bearer ${apiKey}`,
					'Content-Type': 'application/json'
				}

				await axios
					.put(
						`${environment}/api/users/${id}_${router?.query?.addId}_${_loginUserDetails?.id}`,
						{
							name: _name,
							uid: `${id}_${router?.query?.addId}_${_loginUserDetails?.id}`,

							picture: _customer?.photo ? process.env.REACT_APP_IMAGE_URL + _customer?.photo : '/images/icons/icon_Profile.png',
							presence: 'active',
							metadata: {addId: router?.query?.addId ? router?.query?.addId : null, email: _customer?.email, userId: _customer?.id}
						},
						{headers: _headers}
					)
					.then((res) => res.data)
					.catch((err) => {
						if (err?.response?.data?.detail) {
							toast.error(err?.response?.data?.detail)
						}
					})
				_userDetails = await axios.get(`${environment}/api/users/${id}_${router?.query?.addId}_${_loginUserDetails?.id}`, {headers})
				await axios
					.get(url, {headers})
					.then(async (response) => {
						const chatList = response.data.data
						let isGroup = chatList?.find((e: any) => e.display_name == _userDetails?.data?.display_name)
						let conversation = await axios
							.post(`${environment}/api/conversations`, {members: [_userDetails?.data?.id]}, {headers: updateHeader})
							.then((res) => res.data)
							.catch((err) => {
								setLoading(false)
							})

						updateUserDetails({conversationId: conversation?.id, createdId: `${id}_${router?.query?.addId}_${_loginUserDetails?.id}`}, false, _customer)
						setConversationId(conversation?.id)
						await axios.get(url, {headers}).then(async (response) => {
							const chatList = response.data.data
							setChatUsers(chatList)
						})
					})
					.catch((error) => {
						setLoading(false)
						console.log('chatList Error:', error)
					})
				setUserToken(userResponse.data.access_token)
				setLoading(false)
			} else {
				const url = `${environment}/api/conversations`
				const headers = {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${userResponse.data.access_token}`
				}
				await axios
					.get(url, {headers})
					.then(async (response) => {
						const chatList = response.data.data
						setChatUsers(chatList)
					})
					.catch((error) => {
						setLoading(false)
						console.log('chatList Error:', error)
					})
				setUserToken(userResponse.data.access_token)
				setLoading(false)
			}
		} catch (error: any) {
			setLoading(false)
			toast.error('Chat initializing failed')
			console.error('App initialization failed:', error)
		}
	}
	const getAllBlockUsers = async () => {
		await api
			.get('/block_user/all_block_users')
			.then((res) => setBlockUsers(res.data))
			.catch((err) => console.log(err))
	}

	useEffect(() => {
		if (userToken && environment) {
			const client = new WeavyClient({
				url: environment,
				tokenFactory: async () => userToken
			})

			clientRef.current = client
		}
	}, [userToken, environment])

	useEffect(() => {
		if (activeUserId && closeChat == false) {
			getUserDetails(activeUserId)
		}

		if (closeChat) {
			setTimeout(() => {
				setCloseChat(false)
			}, 200)
		}
	}, [activeUserId])

	useEffect(() => {
		if (adId) {
			getPostDetails(adId)
			getPhoneViewStatus(adId)
		}
	}, [adId])

	useEffect(() => {
		const pusher = new Pusher('780302372e2798b5baf6', {
			cluster: 'mt1'
		})
		if (pusher) {
			const channel = pusher.subscribe('loadChat')

			channel.bind('loadChatUsers', function (data: any) {
				if (data?.message && id != data?.data) {
					initializeWeavyApp()
				}
			})

			channel.bind('loadChatMessage', function (data: any) {
				if (data?.message && data?.data?.loadChat && data?.data?.conversactionId?.app_id == conversactionId) {
					getConversationMessage(conversactionId)
				} else if (data?.message && data?.data?.loadChat && data?.data?.conversactionId?.app_id && allConversations?.length) {
					let arr: any = []
					allConversations?.filter((e: any) => {
						if (e.id == data?.data?.conversactionId?.app_id) {
							e.last_message = data.data.conversactionId
							arr.push(e)
						} else {
							arr.push(e)
						}
					})
					if (conversactionId) {
						setTimeout(() => getConversationMessage(conversactionId), 500)
					}
					setAllConversations(arr)
				}
			})

			return () => {
				channel.unbind_all()
				channel.unsubscribe()
			}
		}
	}, [userToken, allConversations.length])

	useEffect(() => {
		const handleElementLogic = () => {
			let elements = document.querySelectorAll('.wy-active')
			if (elements.length) {
				elements.forEach((element: any) => {
					setChatId(element.getAttribute('chatId'))

					element.getAttribute('conversationId') && setConversationId(parseInt(element.getAttribute('conversationId')))

					setAdId(parseInt(element.getAttribute('addId')))

					element.style.color = palette.black
				})
			} else {
			}

			let findId = document.querySelectorAll(`.wy-conversation`)
			let hideMessageFiled: any = document.querySelector('.wy-message-editor-inputs')

			if (findId.length && user) {
				findId.forEach((e) => {
					if (e.getAttribute('id') == user?.id && user?.isBlocked) {
						e.classList.add('blocked-user-style')

						if (hideMessageFiled) {
							hideMessageFiled.style.display = 'none'
						}
					} else if (e.getAttribute('id') == user?.id && !user?.isBlocked) {
						if (hideMessageFiled) {
							hideMessageFiled.style.display = 'flex'
						}
					}
				})
			}

			let elem: any = document.querySelectorAll('.wy-item-title')
			if (elem.length) {
				for (let i = 0; i < elem.length; i++) {
					const parentConversationElement = elem[i].closest('.wy-active')
					if (parentConversationElement == null) {
						elem[i].style.color = _isDarkTheme ? palette.silver : palette.gray
					} else if (parentConversationElement) {
						elem[i].style.color = palette.black
					}
				}
			}
			let _elem: any = document.querySelector('.wy-headline')
			if (_elem) {
				_elem.style.color = _isDarkTheme ? palette.silver : palette.gray
			}
		}

		document.addEventListener('click', handleElementLogic)
		document.addEventListener('keyup', handleElementLogic)

		// Call the logic function once immediately
		handleElementLogic()

		return () => {
			document.removeEventListener('click', handleElementLogic)
			document.removeEventListener('keyup', handleElementLogic)
		}
	}, [_isDarkTheme])

	useEffect(() => {
		const handleElementExistence = () => {
			const wyPaneBodyElement = document.querySelector('.wy-pane-body')

			if (wyPaneBodyElement) {
				observer.disconnect()
			}
		}
		const observer = new MutationObserver(handleElementExistence)
		if (viewPhoneUsers) {
			// Create a MutationObserver
			observer.observe(document, {subtree: true, childList: true})

			setTimeout(() => {
				observer.observe(document, {subtree: true, childList: true})
				return () => {
					observer.disconnect()
				}
			}, 2000)
			return () => {
				observer.disconnect()
			}
		}
	}, [viewPhoneUsers])

	const loadChatNewMessage = async (data: any) => {
		try {
			if (_loginUserDetails?.id) {
				await api
					.post(`customers/chat_pusher`, {data})
					.then(async (response) => response)
					.catch(function (error) {
						console.log(error)
					})
			}
		} catch (error) {}
	}

	const getUserDetails = async (id = null) => {
		if (router?.query?.id || id) {
			setCustomerLoading(true)
			await api
				.get(`customers/${id ? id : router?.query?.id}?page=1&pageSize=15`)
				.then(async (response) => {
					setActiveUserId(response?.data?.customer?.customer?.email)
					setUser(response?.data?.customer?.customer)
					setCustomerLoading(false)
					setCloseChat(false)
				})
				.catch(function (error) {
					setCustomerLoading(false)
					console.log(error)
				})
		}
	}

	const toggleDropdown = () => {
		setDropdownOpen(!isDropdownOpen)
	}

	const handleDocumentClick = (event: any) => {
		if (dropdownRef.current && !dropdownRef?.current?.contains(event.target)) {
			setDropdownOpen(false)
		}
	}

	useEffect(() => {
		document.addEventListener('click', handleDocumentClick)

		return () => {
			document.removeEventListener('click', handleDocumentClick)
		}
	}, [])

	const getPostDetails = async (postId: string) => {
		if (postId) {
			setOtherUserAd(false)
			await api
				.post(`/ads/ad_detail`, {user: _loginUserDetails, adsId: postId})
				.then(async (response: any) => {
					setAddDetails(response?.data?.ads)
					setOfferedPrice(response?.data?.ads?.price)
				})
				.catch(function (error: any) {
					console.log(error)
				})
		}
	}

	const getPhoneViewStatus = async (addId: any) => {
		await api
			.post(`/adsStatics/get_adsStatics`, {addId})
			.then((res: any) => {
				if (res?.data?.length) {
					setViewPhoneUsers(res.data)
				} else {
					setViewPhoneUsers(res.data)
				}
			})
			.catch((err: any) => {
				console.log(err)
			})
	}

	const adsStatics = async (data: any, view: any, tel: any, chats: any) => {
		await api
			.post(`/adsStatics/create_adsStatics`, {
				ownerId: data?.customerIdFkey?.id,
				adsId: data?.id,
				userId: _loginUserDetails?.id,
				viewAd: view,
				tel: tel,
				chat: chats
			})
			.then(async (response) => {
				getPhoneViewStatus(data?.id)
			})
			.catch(function (error) {
				if (error?.response) {
					toast.error(error?.response?.data?.message)
				}
			})
	}
	const deleteChat = async (id: any, userId: any) => {
		try {
			const headers = {
				Authorization: `Bearer ${apiKey}`
			}

			await axios.delete(`${environment}/api/apps/${id}/members/${appId}`, {headers}).then((res) => console.log('resss', res))
			updateUserDetails({conversationId: conversactionId, createdId: userId}, false, user, true)
			initializeWeavyApp()
		} catch (error) {
			console.log('=====error', error)
		}
	}

	const sendMessage = async (id: any, text: any, media = '') => {
		try {
			const headers = {
				Authorization: `Bearer ${userToken}`
			}
			await axios.post(`${environment}/api/apps/${id}/messages`, {text: text ? text : 'hey', blobs: media ? [media] : undefined, meeting_id: media ? null : undefined}, {headers}).then((res: any) => {
				setChatMessage('')
				loadChatNewMessage(res?.data)
				getConversationMessage(id)
			})
		} catch (error) {
			try {
				const headers = {
					Authorization: `Bearer ${apiKey}`
				}
				await axios
					.post(`${environment}/api/apps/${id}/messages`, {text: text ? text : 'hey', blobs: media ? [media] : undefined, meeting_id: media ? null : undefined}, {headers})
					.then((res: any) => {
						setChatMessage('')
						loadChatNewMessage(res?.data)
						getConversationMessage(id)
					})
					.catch((error) => {
						console.log('====error', error)
					})
			} catch (error) {}
		}
	}

	const getConversationById = async (_data = []) => {
		setLoading(true)
		const uniqueData =
			loginUserData?.conversationId &&
			loginUserData?.conversationId?.filter((obj: any, index: any, array: any) => {
				return array.findIndex((item: any) => item?.conversationId === obj?.conversationId) === index
			})
		const headers = {
			Authorization: `Bearer ${apiKey}`
		}

		let arr: any = []
		for (let i = 0; i < uniqueData?.length; i++) {
			if (uniqueData[i]?.conversationId) {
				try {
					console.log('uniqueData[i]?.conversationId', uniqueData[i]?.conversationId)
					const getConversation = await axios.get(`${environment}/api/conversations/${uniqueData[i]?.conversationId}`, {headers})
					if (getConversation.data) {
						arr.push(getConversation.data)
					}
				} catch (error) {
					try {
						const Customheaders = {
							Authorization: `Bearer ${apiKey}`,
							'Content-Type': 'application/json'
						}

						let res = await axios
							.post(
								`${environment}/api/users/${uniqueData[i]?.createdId}/tokens`,
								{
									uid: uniqueData[i]?.createdId,
									expires_in: 14400
								},
								{headers: Customheaders}
							)
							.then((res) => res.data)
							.catch((err) => {
								console.log(err)
							})

						const headers = {
							Authorization: `Bearer ${res?.access_token}`
						}

						const getConversation = await axios.get(`${environment}/api/conversations/${uniqueData[i]?.conversationId}`, {headers})
						if (getConversation.data) {
							let addid = getConversation.data?.members?.data?.length >= 2 && getConversation.data?.members?.data.find((ee: any) => ee?.uid.includes('_')) ? getConversation.data?.members?.data.find((ee: any) => ee?.uid.includes('_')) : getConversation.data?.members?.data?.length >= 2 && getConversation.data?.members?.data.find((ee: any) => ee?.uid.includes('_')).uid

							if (addid?.uid?.includes('_')) {
								addid = addid.uid.split('_')[1]
							}

							arr.push({...getConversation.data, user: await fetchChatUserDetails(getConversation.data?.members?.data[getConversation.data?.members?.data[0]?.display_name == _loginUserDetails?.fullname ? 1 : 0]?.uid, addid)})
						}
					} catch (error) {
						console.log('====error', error)
					}
				}
			}
		}

		let concatData = _data.concat(arr)
		const uniqueSet = new Set()
		const _uniqueData = concatData.filter((item: any) => {
			const identifier = item.id

			if (!uniqueSet.has(identifier)) {
				uniqueSet.add(identifier)
				return true
			}

			return false
		})

		_uniqueData.sort((a: any, b: any) => {
			const timestampA = a.last_message?.created_at || 0
			const timestampB = b.last_message?.created_at || 0

			return timestampB - timestampA
		})

		setAllConversations(_uniqueData)
		setLoading(false)
	}

	useEffect(() => {
		if (userToken && chatUsers?.length) {
			fetchMembers()
		}
	}, [userToken, chatUsers])

	const fetchMembers = async () => {
		let arr: any = []
		for (let i = 0; i < chatUsers?.length; i++) {
			if (chatUsers[i]?.members?.data?.length >= 2) {
				arr.push({...chatUsers[i], user: await fetchChatUserDetails(chatUsers[i]?.members?.data[chatUsers[i]?.members?.data[0]?.display_name == _loginUserDetails?.fullname ? 1 : 0]?.uid)})
			}
		}

		if (loginUserData?.conversationId && userToken) {
			await getConversationById(arr)
		} else {
			setAllConversations(arr)
		}
	}

	const fetchChatUserDetails = async (uid: any, addid = null) => {
		try {
			const headers = {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userToken}`
			}
			let response = await axios.get(`${environment}/api/users/${uid}`, {headers})
			if (response.data?.metadata?.add_id) {
				let _res = await api
					.post(`/ads/ad_detail`, {user: _loginUserDetails, adsId: response.data?.metadata?.add_id})
					.then(async (_response: any) => _response?.data?.ads)
					.catch(function (error: any) {
						console.log(error)
					})
				response.data = {...response.data, ad: _res}
			} else if (addid) {
				let _res = await api
					.post(`/ads/ad_detail`, {user: _loginUserDetails, adsId: addid})
					.then(async (_response: any) => _response?.data?.ads)
					.catch(function (error: any) {
						console.log(error)
					})
				response.data = {...response.data, ad: _res}
			}
			return response.data
		} catch (error) {
			console.error('Error fetching user details:', error)
			// Handle error as needed
			return null
		}
	}

	useEffect(() => {
		if (conversactionId && chatLoad) {
			getConversationMessage(conversactionId)
		}
	}, [conversactionId, chatLoad])

	const scrollToElementById = () => {
		const elements = document.getElementsByClassName('wy-messages')

		if (elements.length > 0) {
			const element = elements[0] // Access the first element in the collection
			element.scrollTop = element.scrollHeight
		} else {
			console.warn(`Element with class "wy-messages" not found.`)
		}
	}

	const getConversationMessage = async (id: any) => {
		try {
			const headers = {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${apiKey}`
			}

			let response = await axios.get(`${environment}/api/apps/${id}/messages?orderby=createdat+asec&skip=0&top=200`, {headers})

			const groupedData = _.groupBy(response.data?.data, (item) => moment(item.created_at).format('YYYY-MM-DD'))
			setMessages(groupedData)

			const sortedData = response.data?.data?.sort((a: any, b: any) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf())
			const mostRecentMessage = sortedData?.length ? sortedData[0] : ''

			let arr: any = []
			allConversations?.filter((e: any) => {
				if (e.id == conversactionId && mostRecentMessage) {
					e.last_message = mostRecentMessage
					arr.push(e)
				} else {
					arr.push(e)
				}
			})

			scrollToElementById()
			window.scrollTo(0, document.body.scrollHeight)

			setAllConversations(arr)
		} catch (error) {
			try {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${userToken}`
				}

				let response = await axios.get(`${environment}/api/apps/${id}/messages?orderby=createdat+asec&skip=0&top=25`, {headers})

				const groupedData = _.groupBy(response.data?.data, (item) => moment(item.created_at).format('YYYY-MM-DD'))
				setMessages(groupedData)

				const sortedData = response.data?.data?.sort((a: any, b: any) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf())
				const mostRecentMessage = sortedData?.length ? sortedData[0] : ''

				let arr: any = []
				allConversations?.filter((e: any) => {
					if (e.id == conversactionId && mostRecentMessage) {
						e.last_message = mostRecentMessage
						arr.push(e)
					} else {
						arr.push(e)
					}
				})
				window.scrollTo(0, document.body.scrollHeight)
				scrollToElementById()

				setAllConversations(arr)
			} catch (error) {
				console.error('Error fetching user details:', error)
			}
		}
	}

	const handleFileChange = async (event: any) => {
		const selectedFile = event.target.files[0]
		// Do something with the selected file
		if (selectedFile) {
			await uploadBlobAttachment(selectedFile)
		}

		console.log('selectedFile', selectedFile)
	}

	const openFilePicker = () => {
		fileInputRef.current.click()
	}

	const uploadBlobAttachment = async (data: any) => {
		const headers = {
			'Content-Type': 'multipart/form-data',
			Authorization: `Bearer ${apiKey}`
		}

		try {
			const formData = new FormData()
			formData.append('blob', data)
			let response = await axios.post(`${environment}/api/blobs`, formData, {headers})
			console.log('=====response', response)
			if (response?.data) {
				await sendMessage(conversactionId, '', response?.data?.id)
			}
		} catch (error) {}
	}

	let calculatedAverageValues = Array.from({length: 5}, (_, index) => addDetails?.price * (1 - index * (7 / 100)))
	const resultArray = Array.from({length: 7}, (_, index) => addDetails?.price * (1 - index * (7 / 100)))

	const percentageChange = ((offeredPrice - addDetails?.price) / addDetails?.price) * 100
	const calculatedNewValue = addDetails?.price + (7 / 100) * addDetails?.price

	let values = ['Hello', 'Is it available?', 'Okay', 'No problem', 'Please reply', 'Not interested']

	const generateImageAiResponse = async (images?: any, text?: any) => {
		try {
			setLoading(true)
			const _text = `Generate relevant short messages for chat to send seller ${text ? 'by using add details' + JSON.stringify(text) : 'image'} and messages like this ${JSON.stringify(values)}. Ensure details are valid and accurate. Give Response in array.`
			let res: any = await doGenerateAiResponse(_text, images)
			if (res) {
				console.log('ressss', res)

				let data = verifyJSON(res) ? JSON.parse(res) : []
				data?.length && setDummyMessages(data)
				setLoading(false)
				if (res?.match(/```json([\s\S]*?)```/)) {
					const stringWithEmbeddedJSON = res

					const jsonMatch = stringWithEmbeddedJSON.match(/```json([\s\S]*?)```/)
					setDummyMessages(jsonMatch)
					if (jsonMatch && jsonMatch[1]) {
						const extractedJSON = jsonMatch[1].trim()

						try {
							const parsedObject: any = JSON.parse(extractedJSON)
							setDummyMessages(parsedObject)
							console.log('parsedObject:', parsedObject)
						} catch (error) {
							console.error('Error parsing JSON:', error)
						}
					} else {
						console.error('No JSON found in the string.')
					}
				} else {
					// setAIResponse(res)
				}
			}
		} catch (error) {
			setLoading(false)
			console.log('error', error)
		}
	}

	useEffect(() => {
		if (conversactionId && addDetails) {
			generateImageAiResponse('', addDetails)
		}
	}, [addDetails])

	return (
		<>
			{(loading || customerLoading) && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}

			<Main>
				<Flexed direction="row" align="center" gap="0.5" margin="1.25rem 0rem" pointer onClick={() => router.navigate(-1)}></Flexed>
				<Row>
					<Col id={'abc'}>
						<div className="app-bar">
							<Col id={'abc'} col={4.36} style={{padding: '0', background: _isDarkTheme ? palette.black : '#ebeeef', color: _isDarkTheme ? palette.silver : palette.gray}}>
								<div className="inbox-text">Inbox</div>
							</Col>
							<Col id={'abc'} col={3.7}>
								{(router.query?.id || activeUserId) && user?.id && user?.id != _loginUserDetails?.id && (
									<div className="user-profile" onClick={() => router.navigate(`/dealer-profile?id=${user?.id}`)}>
										<div className="user-info">
											<img src={user?.photo ? process.env.REACT_APP_IMAGE_URL + user?.photo : '/images/icons/icon_Profile.png'} alt="User Avatar" />
											<CustomrName className="user-name" style={{color: _isDarkTheme ? palette.silver : palette.gray}}>
												{user?.fullname}
											</CustomrName>
										</div>
									</div>
								)}
							</Col>
							<Col id={'abc'} col={4} style={{alignItems: 'center', display: 'flex', justifyContent: 'flex-end'}}>
								{(router.query?.id || activeUserId) && user?.id && user?.id != _loginUserDetails?.id && (
									<>
										<div style={{display: 'flex', alignItems: 'center'}}>
											<div className="dropdown-option" onClick={() => setReportBlockUser(true)}>
												<FlagIcon isDarkTheme={_isDarkTheme} />
											</div>

											{user?.showNoInAds ? (
												<>
													<div
														className="dropdown-option"
														onClick={() => {
															setCallModel({show: true, isCall: true})
															// sendMessage()
															if (addDetails) {
																adsStatics(addDetails, false, true, false)
															}
														}}>
														<CallIcons isDarkTheme={_isDarkTheme} />
													</div>
													<div className="dropdown-option" onClick={() => setCallModel({show: true, isCall: false})}>
														<SmsIcon isDarkTheme={_isDarkTheme} />
													</div>
												</>
											) : (
												''
											)}
										</div>

										<div className="options-dropdown" ref={dropdownRef}>
											<div className="dropdown-option" onClick={toggleDropdown}>
												<VerticleDotIcon isDarkTheme={_isDarkTheme} />
											</div>

											{isDropdownOpen && (
												<div className="dropdown-content">
													<div onClick={() => setSafetyTipsModel(true)}>Safety Tip</div>

													{user?.isBlocked && !user?.isBlockedMe ? <div onClick={() => setBlockUser(true)}> {user?.isBlocked && !user?.isBlockedMe ? 'Unblock User' : 'Block User'} </div> : <div onClick={() => setBlockUser(true)}>Block User</div>}
													<div onClick={() => setReportBlockUser(true)}>Report User</div>
												</div>
											)}
										</div>

										<div
											className="dropdown-option"
											onClick={() => {
												setCloseChat(true)
												setActiveUserId(null)
												setUser('')
											}}>
											<CloseIcon isDarkTheme={_isDarkTheme} />
										</div>
									</>
								)}
							</Col>
						</div>
					</Col>
					<Col>
						{userToken && environment ? (
							<WeavyProvider client={clientRef.current}>
								<MessengerProvider>
									<ChatWrapper>
										<ChatList ref={userRef}>
											{allConversations?.map((res: any, ind: any) => {
												let parts = res?.members?.data?.length >= 2 && res?.members?.data[1]?.uid.includes('_') ? res?.members?.data[1]?.uid?.split('_')[1] : res?.members?.data?.length >= 2 && res?.members?.data[1]?.uid
												return res?.members?.data?.length >= 2 ? (
													<ChatItemContainer
														key={ind}
														id={parts ? parts : parseInt(res?.members?.data[1]?.uid)}
														{...{chatId: parts, addId: res?.user?.ad?.id, email: res?.user?.metadata?.email}}
														addId={res?.user?.ad?.id}
														email={res?.user?.metadata?.email}
														className={`wy-item wy-item-lg wy-item-hover wy-conversation ${blockUsers?.length && blockUsers?.includes(parseInt(res?.user?.metadata?.user_id)) ? ' blocked-user-style' : ''} ${conversactionId == res?.id ? 'wy-active' : ''} `}
														onClick={async () => {
															setChatLoad(false)
															setConversationId(res?.id)
															setActiveUserId(res?.user?.metadata?.email ? res?.user?.metadata?.email : res?.user?.metadata?.user_id)
															setAdId(res?.user?.ad?.id)
															getConversationMessage(res?.id)
														}}>
														<AvatarPresence className={`wy-avatar-presence`}>
															<Avatar src={res?.members?.data[0]?.display_name == _loginUserDetails?.fullname ? res?.members?.data[1]?.avatar_url : res?.members?.data[0]?.avatar_url} alt="" title={res?.display_name} className="wy-avatar" height="48" width="48" />
															<Presence className="wy-presence" data-presence-id="-1"></Presence>
														</AvatarPresence>
														<ItemBody className="wy-item-body">
															<ItemRow className="wy-item-row">
																<ItemTitle className="wy-item-title">
																	{res?.display_name}
																	{res?.user?.ad ? (
																		<p id="yourElement" className="add-title-text" title={res?.user?.ad?.title}>
																			{res?.user?.ad?.title}
																		</p>
																	) : (
																		''
																	)}
																</ItemTitle>

																<Meta className="wy-meta" title={res?.last_message?.created_at ? res?.last_message?.created_at : res?.created_at}>
																	{moment(res?.last_message?.created_at ? res?.last_message?.created_at : res?.created_at).fromNow()}
																</Meta>
															</ItemRow>
															<ItemRow className="wy-item-row">
																<ItemText className="wy-item-text">
																	<span></span>
																	<span>
																		{res?.last_message ? (
																			<span className="wy-typing-hide">
																				{res?.last_message?.plain?.includes('Your offer') ? '' : 'You:'} {res?.last_message ? res?.last_message?.plain : ''}
																			</span>
																		) : (
																			''
																		)}
																	</span>
																	{res?.user?.ad?.activate == false || res?.user?.ad?.isBlockedAd || res?.user?.ad?.sold ? <div className="blocked-text">{res?.user?.ad?.sold ? 'Ad sold' : 'Ad inactive.'}</div> : ''}
																	{blockUsers?.includes(parseInt(res?.user?.metadata?.user_id)) ? <div className="blocked-text">Conversation with this user is blocked.</div> : ''}
																</ItemText>
															</ItemRow>
														</ItemBody>
														<ItemActions className="wy-item-actions wy-item-actions-bottom">
															<DropMenu>
																<VerticleDotIcon isDarkTheme={_isDarkTheme} />
																<Drop id="dropdown" isDarkTheme={_isDarkTheme}>
																	<DropList isDarkTheme={_isDarkTheme} onClick={() => deleteChat(res?.id, res?.user?.id)}>
																		Delete Chat
																	</DropList>
																</Drop>
															</DropMenu>
														</ItemActions>
													</ChatItemContainer>
												) : (
													''
												)
											})}
										</ChatList>
										<ChatWindow isDarkTheme={_isDarkTheme}>
											{(user && addDetails && activeUserId) || (user && otherUserAd && activeUserId) ? (
												<CustomCol col={12} isDarkTheme={_isDarkTheme}>
													<div style={{display: 'flex', alignItems: 'center', flex: '1'}}>
														<AdImage src={addDetails?.photos?.length ? (verifyJSON(addDetails?.photos) ? process.env.REACT_APP_IMAGE_URL + JSON.parse(addDetails?.photos)[0] : process.env.REACT_APP_IMAGE_URL + addDetails?.photos[0]) : process.env.REACT_APP_IMAGE_URL + addDetails?.photos[0]} alt="Ad" />
														<AdDetails>
															<h4 style={{fontWeight: '400', maxWidth: '30rem'}}>{addDetails?.title}</h4>
															<p> Rs {addDetails?.price.toLocaleString()}</p>
														</AdDetails>
													</div>
													<ViewButton onClick={() => router.navigate(`/post-details?id=${addDetails?.id}`)}>View Ad</ViewButton>
												</CustomCol>
											) : (
												''
											)}
											{user?.id ? (
												<PaneBody className="wy-pane-body" style={{position: 'relative', zIndex: '00000'}} isDarkTheme={_isDarkTheme}>
													<AvatarHeader className="wy-avatar-header">
														<Headline className="wy-headline">{user?.fullname}</Headline>
													</AvatarHeader>
													{viewPhoneUsers?.map((ree: any) => {
														return (
															<div className="phoneView" style={{textAlign: 'center'}}>
																<span className="chatTodayElement">{moment(ree?.date).calendar().split(' at ')[0]}</span>
																{ree?.items?.map((e: any, index: any) => (
																	<div className="phone-number-viewed" key={index}>
																		<div className="image-container">
																			<BiMobile fontSize={'1.5rem'} />
																			Phone number viewed at {moment(e?.createdAt).format('hh:mm A')}
																		</div>
																	</div>
																))}
															</div>
														)
													})}
													{Object.keys(messages)?.length ? (
														Object.keys(messages).map((date: any, ind: any) => {
															return (
																<MessagesContainer key={ind} id="container" className="wy-messages">
																	<DateSeparator className="wy-date-separator">
																		<time>{moment(date).format('MM/DD/YYYY')}</time>
																	</DateSeparator>
																	{messages[date].map((res: any) => (
																		<>
																			{addDetails?.customerId != _loginUserDetails?.id ? (
																				<>
																					{res?.created_by?.display_name == 'System' ? (
																						<div className="wy-message">
																							<div className="wy-message-author">
																								<div className="wy-avatar-presence">
																									<img alt="" title={res?.created_by?.display_name} className="wy-avatar" height="32" width="32" src={res?.created_by?.avatar_url ? res?.created_by?.avatar_url : process.env.REACT_APP_IMAGE_URL + user?.photo} />
																								</div>
																							</div>
																							<div className="wy-message-content">
																								<div className="wy-message-meta">
																									<span>{res?.created_by?.display_name == 'System' ? user?.fullname : res?.created_by?.display_name} </span>
																									<time title={moment(res?.created_at).fromNow()}>{moment(res?.created_at).fromNow()}</time>
																								</div>
																								<div className="wy-message-bubble">
																									<div className="wy-content" dangerouslySetInnerHTML={{__html: res?.html}}></div>
																									{res?.attachments && res?.attachments?.length
																										? res?.attachments?.map((re: any) => {
																												return (
																													<div className="wy-content">
																														<img src={re?.preview_url} width={re?.width} height={re?.height} alt={re?.name}></img>
																													</div>
																												)
																										  })
																										: ''}
																								</div>
																							</div>
																						</div>
																					) : _loginUserDetails?.fullname == res?.created_by?.display_name ? (
																						<Message className="wy-message wy-message-me">
																							<MessageContent className="wy-message-content">
																								<MessageMeta className="wy-message-meta">
																									<time title={moment(res?.created_at).format('DDD, MMM MM, YYYY HH:mm A')}>{moment(res?.created_at).fromNow()}</time>
																								</MessageMeta>
																								<MessageBubble className="wy-message-bubble">
																									<div className="wy-content" dangerouslySetInnerHTML={{__html: res?.html}}></div>
																								</MessageBubble>
																							</MessageContent>
																						</Message>
																					) : (
																						''
																					)}
																				</>
																			) : (
																				<>
																					{_loginUserDetails?.fullname != res?.created_by?.display_name && res?.created_by?.display_name != 'System' ? (
																						<div className="wy-message">
																							<div className="wy-message-author">
																								<div className="wy-avatar-presence">
																									<img alt="" title={res?.created_by?.display_name} className="wy-avatar" height="32" width="32" src={res?.created_by?.avatar_url ? res?.created_by?.avatar_url : process.env.REACT_APP_IMAGE_URL + user?.photo} />
																								</div>
																							</div>
																							<div className="wy-message-content">
																								<div className="wy-message-meta">
																									<span>{res?.created_by?.display_name == 'System' ? user?.fullname : res?.created_by?.display_name} </span>
																									<time title={moment(res?.created_at).fromNow()}>{moment(res?.created_at).fromNow()}</time>
																								</div>
																								<div className="wy-message-bubble">
																									<div className="wy-content" dangerouslySetInnerHTML={{__html: res?.html}}></div>
																								</div>
																							</div>
																						</div>
																					) : _loginUserDetails?.fullname == res?.created_by?.display_name || res?.created_by?.display_name == 'System' ? (
																						<Message className="wy-message wy-message-me">
																							<MessageContent className="wy-message-content">
																								<MessageMeta className="wy-message-meta">
																									<time title={moment(res?.created_at).format('DDD, MMM MM, YYYY HH:mm A')}>{moment(res?.created_at).fromNow()}</time>
																								</MessageMeta>
																								<MessageBubble className="wy-message-bubble">
																									<div className="wy-content" dangerouslySetInnerHTML={{__html: res?.html}}></div>
																								</MessageBubble>
																							</MessageContent>
																						</Message>
																					) : (
																						''
																					)}
																				</>
																			)}
																		</>
																	))}
																</MessagesContainer>
															)
														})
													) : (
														<Spacer height={10} />
													)}
													{user?.isBlocked || addDetails?.activate == false || addDetails?.isBlockedAd || addDetails?.sold ? (
														''
													) : (
														<Footerbar className="wy-footerbar wy-footerbar-sticky" isDarkTheme={_isDarkTheme} style={{color: _isDarkTheme ? palette.silver : palette.gray, background: _isDarkTheme ? `${palette.dark}` : `${palette.white}`}}>
															{addDetails?.customerId != _loginUserDetails?.id ? (
																<ChatAssistantWrapper isDarkTheme={_isDarkTheme}>
																	<ExpandIcon isDarkTheme={_isDarkTheme} onClick={handleExpandClick}>
																		{isExpanded ? <MdKeyboardArrowDown style={{fontSize: 'xx-large'}} /> : <MdKeyboardArrowUp style={{fontSize: 'xx-large'}} />}
																	</ExpandIcon>
																	<TabsContainer>
																		<TabItem
																			selected={selectedTab === 'questions'}
																			onClick={() => {
																				handleTabClick('questions')
																				if (isExpanded == false) {
																					handleExpandClick()
																				}
																			}}>
																			<StyledIcon isDarkTheme={_isDarkTheme}>
																				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill-rule="evenodd" data-aut-id="icon" viewBox="0 0 1024 1024">
																					<path d="M598.7 473.7H668V407h-69.2v66.7zm58.2-243c-18 0-33.2 3.1-45.5 9.5a61.9 61.9 0 0 0-27 25.8 66.4 66.4 0 0 0-7.7 35.4h65.7c0-4.2 1-7.6 3-10.2 2-2.7 5-4 9-4 3.1 0 5.6 1 7.6 3.2 2 2 3 5 3 8.5 0 6.5-3.2 11.1-9.7 14a76 76 0 0 1-28.6 4.2h-26.4v66.6h65.4v-22.3c13.2-.2 25-3 35.2-8.3a58.8 58.8 0 0 0 32.4-54.6c0-22.4-7-39.3-20.8-50.8-13.8-11.4-32.3-17-55.6-17zM855.5 352a183.2 183.2 0 0 0-182.4-183.6h-49.4A183.2 183.2 0 0 0 441.2 352v223.8l80-40.2H673A183.3 183.3 0 0 0 855.4 352zm79.8 0a263.1 263.1 0 0 1-262.2 263.4H540l-105 52.9-73.7-45.8V352.1A263.1 263.1 0 0 1 623.7 88.6H673a263.2 263.2 0 0 1 262.2 263.5zm-308 301.8l14.9 14.8v189.9L570 903.4l-101.2-51h-127a254 254 0 0 1-253.1-254C88.7 458.1 192.9 344 332.4 344v80c-95.6 0-164 78.3-164 174.4s77.8 174.4 173.3 174.4h146l74.6 37.5V668.7l14.9-14.8h50.1z"></path>
																				</svg>
																				<span className="_90151c79">Questions</span>
																			</StyledIcon>
																		</TabItem>
																		<TabItem
																			selected={selectedTab === 'offer'}
																			onClick={() => {
																				handleTabClick('offer')
																				if (isExpanded == false) {
																					handleExpandClick()
																				}
																			}}>
																			<StyledIcon isDarkTheme={_isDarkTheme}>
																				<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1024 1024">
																					<path d="M395.7 281.3L775 283l1-192-379.3-1.7-.9 191.9zm-45 89l-44.4-44.9 1.3-281L352.5 0l468.4 2.2 44.3 44.9-1.3 281-44.8 44.4-468.4-2.3zm530.5 195.2l-33-32.9L659.8 689H384v-92.6h202.7l-.1-50.8H256.7l-164 183.3v162l64.3-.4 90.8-90.8 32.7-13.6h380.2l220.5-220.6zm98.3 32.7l-266.8 267-32.8 13.5H299.7l-90.6 90.7-32.4 13.6-130 .9L0 937.5V711.1l11.8-30.9 189.7-211.9L236 453h396.7l46.3 46 .2 51.6 143-116.3 62 3.2 95.3 95.1v65.5zM659.9 186.6a74 74 0 1 1-148.2-.7 74 74 0 0 1 148.2.7z"></path>
																				</svg>
																				<span className="_90151c79">Offer</span>
																			</StyledIcon>
																		</TabItem>
																	</TabsContainer>
																	{isExpanded && (
																		<TabContent isDarkTheme={_isDarkTheme}>
																			{selectedTab === 'questions' && (
																				<div>
																					<ChatAssistantContent isDarkTheme={_isDarkTheme}>
																						<div style={{display: 'block', textAlign: 'justify'}}>
																							<ChatToKnowMore>Chat to know more!</ChatToKnowMore>
																							<CloseToDealMore>Close the deal faster by asking more about the product or person.</CloseToDealMore>
																						</div>

																						<picture>
																							<SmsIconNoChat style={{fontSize: '2rem'}} isDarkTheme={_isDarkTheme} />
																						</picture>
																					</ChatAssistantContent>
																					<ChatAssistantButtons isDarkTheme={_isDarkTheme}>
																						{dummyMessages?.length ? (
																							dummyMessages?.map((res: any) => <Button onClick={() => sendMessage(conversactionId, res)}>{res}</Button>)
																						) : (
																							<>
																								<Button onClick={() => sendMessage(conversactionId, 'Hello')}>Hello</Button>
																								<Button onClick={() => sendMessage(conversactionId, 'Is it available?')}>Is it available?</Button>
																								<Button onClick={() => sendMessage(conversactionId, 'Okay')}>Okay</Button>
																								<Button onClick={() => sendMessage(conversactionId, 'No problem')}>No problem</Button>
																								<Button onClick={() => sendMessage(conversactionId, 'Please reply')}>Please reply</Button>
																								<Button onClick={() => sendMessage(conversactionId, 'Not interested')}>Not interested</Button>
																							</>
																						)}
																					</ChatAssistantButtons>
																				</div>
																			)}
																			{selectedTab === 'offer' && (
																				<div>
																					<Wrapper isDarkTheme={_isDarkTheme}>
																						<PriceContainer>
																							<OfferParentDiv isDarkTheme={_isDarkTheme}>
																								{Array.from({length: 5}, (_, index) => addDetails?.price * (1 - index * (7 / 100))).map((e: any) => (
																									<PriceItem isDarkTheme={_isDarkTheme} selected={offeredPrice == parseInt(e)} onClick={() => setOfferedPrice(parseInt(e))}>
																										{parseInt(e).toLocaleString()}
																									</PriceItem>
																								))}
																							</OfferParentDiv>
																						</PriceContainer>

																						<div>
																							<OfferContainer>
																								<InputContainer>
																									<CurrencySymbol>Rs</CurrencySymbol>
																									<PriceInput isDarkTheme={_isDarkTheme} id="price" name="price" autoComplete="off" spellCheck="false" defaultValue={parseInt(offeredPrice)} value={offeredPrice} onChange={(e: any) => setOfferedPrice(e.target.value)} />
																								</InputContainer>
																								<OfferToolTipWrapper>
																									<OfferText large={percentageChange >= 7} vgood={calculatedAverageValues.some((e: any) => offeredPrice >= e)} good={resultArray?.slice(5).some((e: any) => offeredPrice >= e)} bad={resultArray?.slice(5).some((e: any) => offeredPrice <= e)}>
																										<div style={{display: 'flex', alignItems: 'center'}}>
																											{percentageChange >= 7 ? (
																												<svg width="24" height="24" fill-rule="evenodd" data-aut-id="icon" viewBox="0 0 1024 1024" style={{fill: '#fff', minWidth: '2rem', marginRight: '0.8rem'}}>
																													<path d="M512 85.3c235.3 0 426.7 191.4 426.7 426.7S747.3 938.7 512 938.7C276.7 938.7 85.3 747.3 85.3 512S276.7 85.3 512 85.3zm0 85.4c-188.2 0-341.3 153-341.3 341.3s153 341.3 341.3 341.3c188.2 0 341.3-153 341.3-341.3S700.3 170.7 512 170.7zm0 469.3a42.7 42.7 0 1 1 0 85.4 42.7 42.7 0 0 1 0-85.4zm0-341.3l42.7 42.6v213.4L512 597.3l-42.7-42.6V341.3l42.7-42.6z"></path>
																												</svg>
																											) : calculatedAverageValues.some((e: any) => offeredPrice >= e) ? (
																												<svg width="24" height="24" fill-rule="evenodd" viewBox="0 0 1024 1024" style={{fill: '#fff', minWidth: '2rem', marginRight: '0.8rem'}}>
																													<path d="M435.3 877H716v-71.2H435.3V877zm-43.6-341.2L441 510a3438 3438 0 0 0 42.8 20.3l.8 50.3-35.7 14c-13-8-29.2-19.7-38.8-26.9l-18.4-32zm-68.4 42l16.5-11 24.6 43 4.8 3.7c4.2 3.2 41.4 31.6 65 42.8l11.5 5.5 99.7-39-2.2-132.4-17.4-8c-27.7-12.8-72.4-34.4-72.5-34.4l-13.3-6.5-78.8 41-1.7-3-4.6 3.2-14.3-16.6 85.6-71.7 216.4 94.2V442c0-2.9 0-99.6-.3-144.3V287l-4.7-6.2-51-98.5 6-20.9h23.3L697 299v421.4H443.7a2167.3 2167.3 0 0 1-85-60.2l-35.3-82.3zM438.4 147h20.7l123.1 161.5.1 87-45.9-20v-69l-5.5-7.6L431.7 157l6.7-10zm37.9 202.4l-31.5-13.7-4-1.8-45.3-64.4.4-23.2 19.2-3.8 61.2 84.3v22.6zm280.9 406.1V281.7L649.8 100H547.6l-13.1 45.7-46.1-60.4h-81.7l-48.5 72.5 20.4 29-42 8.3-1.5 93.7 45.2 64.5-126 105.7 50 58-56.4 38 58.6 136.5h.2c8.3 13.1 49.2 43.6 83 65.6L375.3 772v136l30 30.8h340.9l30-30.8V775l-19-19.5z"></path>
																												</svg>
																											) : resultArray?.slice(5).some((e: any) => offeredPrice >= e) ? (
																												<svg width="24" height="24" fill-rule="evenodd" data-aut-id="icon" viewBox="0 0 1024 1024" style={{fill: '#fff', minWidth: '2rem', marginRight: '0.8rem'}}>
																													<path d="M393.5 85.3L325.7 126v178.3l-61.3 153.9H131.3l-46 46v388.5l46 46H722l42.8-29L938.7 470 896 407H563.4V205l-24-40.5-145.9-79.2zm-55.7 436.2l76.5-191.4 3.3-17V203l54 29.4V453l45.9 46.1h310.8L690.9 846.5H337.8v-325zm-160.6 325h68.7V550.1h-68.7v296.4z"></path>
																												</svg>
																											) : (
																												<svg width="24" height="24" fill-rule="evenodd" data-aut-id="icon" viewBox="0 0 1024 1024" style={{fill: '#fff', minWidth: '2rem', marginRight: '0.8rem'}}>
																													<path d="M512 85.3c235.3 0 426.7 191.4 426.7 426.7S747.3 938.7 512 938.7C276.7 938.7 85.3 747.3 85.3 512S276.7 85.3 512 85.3zm0 85.4c-188.2 0-341.3 153-341.3 341.3s153 341.3 341.3 341.3c188.2 0 341.3-153 341.3-341.3S700.3 170.7 512 170.7zm0 469.3a42.7 42.7 0 1 1 0 85.4 42.7 42.7 0 0 1 0-85.4zm0-341.3l42.7 42.6v213.4L512 597.3l-42.7-42.6V341.3l42.7-42.6z"></path>
																												</svg>
																											)}
																											<div style={{textAlign: 'justify'}}>
																												<OfferTitle> {percentageChange >= 7 ? 'Your offer is too high' : calculatedAverageValues.some((e: any) => offeredPrice >= e) ? 'Very good offer!' : resultArray?.slice(5).some((e: any) => offeredPrice >= e) ? 'Good offer' : resultArray?.slice(5).some((e: any) => offeredPrice <= e) ? 'Too low offer' : ''}</OfferTitle>
																												<OfferDescription>{percentageChange >= 7 ? `Your offer should be less than Rs ${parseInt(calculatedNewValue).toLocaleString()}` : calculatedAverageValues.some((e: any) => offeredPrice >= e) ? `High chances of seller’s reply.` : resultArray?.slice(5).some((e: any) => offeredPrice >= e) ? 'More chances of seller’s response.' : resultArray?.slice(5).some((e: any) => offeredPrice <= e) ? <span style={{textDecoration: 'underline'}}>Similar products in your budget.</span> : ''} </OfferDescription>
																											</div>
																										</div>
																									</OfferText>
																									<ButtonContainer>
																										<SendOfferButton
																											onClick={() => {
																												if (calculatedAverageValues.some((e: any) => offeredPrice >= e) || resultArray?.slice(5).some((e: any) => offeredPrice >= e)) {
																													if (offeredPrice) sendMessage(conversactionId, `Your offer: Rs ${parseInt(offeredPrice).toLocaleString()}`)
																												}
																											}}
																											active={calculatedAverageValues.some((e: any) => offeredPrice >= e) || resultArray?.slice(5).some((e: any) => offeredPrice >= e)}>
																											<span>Send</span>
																										</SendOfferButton>
																									</ButtonContainer>
																								</OfferToolTipWrapper>
																							</OfferContainer>
																						</div>
																					</Wrapper>
																				</div>
																			)}
																		</TabContent>
																	)}
																</ChatAssistantWrapper>
															) : (
																''
															)}
															{selectedTab === 'offer' ? (
																''
															) : (
																<MessageEditor className="wy-message-editor" isDarkTheme={_isDarkTheme}>
																	<MessageEditorInputs className="wy-message-editor-inputs">
																		<FileButton isDarkTheme={_isDarkTheme} type="button" className="wy-button wy-button-icon" title="" onClick={openFilePicker}>
																			<BsPlus style={{fontSize: '2rem'}} />
																		</FileButton>

																		<input type="file" accept=".jpg, .png, .jpeg" onChange={handleFileChange} ref={fileInputRef} style={{display: 'none'}} />
																	</MessageEditorInputs>
																	<EditorText className="wy-message-editor-text">
																		<div className="cm-theme-light">
																			<div className="cm-editor">
																				<CustomTextArea isDarkTheme={_isDarkTheme} placeholder="Type a message" className="centered-textarea" aria-label="Type a message" value={chatMessage} onChange={(e: any) => setChatMessage(e.target.value)}></CustomTextArea>
																			</div>
																		</div>
																	</EditorText>
																	<SendButton isDarkTheme={_isDarkTheme} type="button" className="wy-button wy-button-icon" title="" onClick={() => chatMessage.trim().length && sendMessage(conversactionId, chatMessage)}>
																		<svg viewBox="0 0 24 24" role="presentation" className="wy-icon wy-icon-primary" data-icon="send">
																			<path d="M2,21L23,12L2,3V10L17,12L2,14V21Z"></path>
																		</svg>
																	</SendButton>
																</MessageEditor>
															)}
														</Footerbar>
													)}
												</PaneBody>
											) : (
												<NoChatContent isDarkTheme={_isDarkTheme}>
													<div style={{textAlign: 'center'}}>
														<SmsIconNoChat isDarkTheme={_isDarkTheme} />
														<div style={{color: _isDarkTheme ? palette.silver : palette.gray}}>Select a chat to view conversation</div>
													</div>
												</NoChatContent>
											)}
											{user?.isBlocked ? (
												<CustomBlockElement isModel={reportUserModal || blockUserModal}>
													<p>Conversation with this user is blocked.</p>
													{user?.isBlockedMe ? '' : <UnBlock onClick={() => setBlockUser(true)}>Unblock</UnBlock>}
												</CustomBlockElement>
											) : (addDetails?.activate == false || addDetails?.isBlockedAd) && addDetails ? (
												<CustomBlockElement isModel={reportUserModal || blockUserModal}>
													<p>Ad inactive.</p>
												</CustomBlockElement>
											) : addDetails?.sold && addDetails ? (
												<CustomBlockElement isModel={reportUserModal || blockUserModal}>
													<p>Ad sold</p>
												</CustomBlockElement>
											) : (
												''
											)}
										</ChatWindow>
									</ChatWrapper>
								</MessengerProvider>
							</WeavyProvider>
						) : (
							<div></div>
						)}
					</Col>
				</Row>
			</Main>

			<ReportUserModal
				open={reportUserModal}
				onClick={() => {
					getAllBlockUsers()
					getUserDetails(user?.id)
					setReportBlockUser(false)
				}}
				onCloseModal={() => {
					setReportBlockUser(false)
				}}
				user={user}
			/>
			<SafetyTipsModal
				open={safetyTipsModel}
				onClick={() => {
					setSafetyTipsModel(false)
				}}
				onCloseModal={() => {
					setSafetyTipsModel(false)
				}}
			/>
			<BlockUserModal
				open={blockUserModal}
				isBlocked={user?.isBlocked}
				onClick={() => {
					getAllBlockUsers()
					getUserDetails(user?.id)
					setBlockUser(false)
				}}
				user={user}
				onCloseModal={() => {
					setBlockUser(false)
				}}
				isChat={true}
			/>

			<PhoneModal open={callModel.show} onCloseModal={() => setCallModel({show: false, isCall: false})} isPhone={callModel.isCall} isSms={!callModel.isCall} number={user?.phone} />
		</>
	)
}

const Wrapper = styled.div<any>`
	display: flex;
	flex-direction: column;
	padding: 20px;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)} !important;
	background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark}` : `#ebf1ff`)} !important;
`

const PriceContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
`

const OfferParentDiv = styled.div<any>`
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)} !important;
	background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark}` : `#ebf1ff`)} !important;
	${media.xs`
	display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    `};
	${media.sm`
	display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    `};
	${media.md`
	display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    `};
	${media.lg`
	display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    `};
`
const PriceItem = styled.span<any>`
	font-size: 1.204rem;
	padding: 0.5rem 1.2rem;
	border: 0.1rem solid ${(props) => (props.selected ? '#c8f8f6' : 'rgba(0,47,52,.64)')};
	margin-right: 0.8rem;
	border-radius: 1.4rem;
	color: rgba(0, 47, 52, 0.64);
	cursor: pointer;
	font-weight: ${(props) => (props.selected ? '700' : '')};
	color: ${({isDarkTheme, selected}) => (selected ? '#002f34' : isDarkTheme ? palette.silver : '#002f34')} !important;
	background: ${({isDarkTheme, selected}) => (selected ? '#c8f8f6' : isDarkTheme ? `${palette.dark}` : `#ebf1ff`)} !important;
`

const InputContainer = styled.div`
	display: flex;
	align-items: center;
`

const CurrencySymbol = styled.span`
	font-size: 18px;
`

const PriceInput = styled.input<any>`
	padding: 5px;
	border: none;
	margin-left: 5px;
	background: none;
	border-bottom: 1px solid;

	color: ${({isDarkTheme, selected}) => (isDarkTheme ? palette.silver : palette.gray)} !important;
	&:focus {
		outline: none;
		border-bottom: 1px solid;
	}
`

const OfferContainer = styled.div`
	display: block;
	align-items: center;
	margin-bottom: 20px;
`

const OfferToolTipWrapper = styled.div<any>`
	margin-top: 1rem;

	align-items: center;
	${media.xs`
	display: flex;
   flex-direction: column;
   gap: 1rem;
    `};
	${media.sm`
	display: flex;
   flex-direction: column;
   gap: 1rem;
    `};
	${media.md`
	display: flex;
   flex-direction: row;
    `};
	${media.lg`
	display: flex;
   flex-direction: row;
    `};
`
const OfferText = styled.div<any>`
	display: flex;
	flex-direction: column;
	align-items: start;
	background: ${(props) => (props?.large ? '#f0817a' : props.vgood ? '#4cadb3' : props.good ? '#6597b1' : '#f0817a')};
	padding: 0.8rem;
	border-radius: 4px;
	height: auto;
	position: relative;
	color: #fff;
	&:before {
		content: '';
		position: absolute;
		top: -2rem;
		left: 20%;
		margin-left: -5px;
		border-width: 16px;
		border-style: solid;
		border-color: transparent transparent ${(props) => (props?.large ? '#f0817a' : props.vgood ? '#4cadb3' : props.good ? '#6597b1' : '#f0817a')} transparent;
	}
`

const OfferTitle = styled.div`
	font-weight: bold;
`

const OfferDescription = styled.div``

const ButtonContainer = styled.div`
	margin-left: 1rem;
`

const SendOfferButton = styled.button<any>`
	border: 0;
	border-radius: 0.4rem;
	height: 48px;
	max-height: 100%;
	padding: 0 1rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-align: center;
	color: ${(props) => (props?.active ? '#fff' : '#7f9799')};
	cursor: ${(props) => (props?.active ? 'pointer' : 'not-allowed')};
	background-color: ${(props) => (props?.active ? '#002f34' : '#d8dfe0')};
`

const ExpandIcon = styled.div<any>`
	position: absolute;
	left: 50%;
	top: -1.9rem;
	-webkit-transform: translate(-50%);
	-ms-transform: translate(-50%);
	transform: translate(-50%);
	display: inline-block;
	width: 4rem;
	height: 2rem;
	margin: auto;
	background-color: #fff;
	border-radius: 3rem 3rem 0 0;
	-webkit-box-shadow: 0 -0.2rem 0.4rem 0 rgba(0, 0, 0, 0.06);
	box-shadow: 0 -0.2rem 0.4rem 0 rgba(0, 0, 0, 0.06);
	cursor: pointer;
	z-index: 4;
	justify-content: center;
	text-align: center;
	display: flex;
	align-items: center;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)} !important;
	background: ${({isDarkTheme, isActive}) => (isActive ? (isDarkTheme ? `${palette.gray_gradient}` : `${palette.blue_mirror}`) : isDarkTheme ? `${palette.dark}` : `${palette.white}`)} !important;
`
const TabsContainer = styled.div`
	display: flex;
	justify-content: space-around;
	border-bottom: 2px solid #ddd;
	margin-bottom: 20px;
`

const TabItem = styled.div<any>`
	padding: 10px 20px;
	cursor: pointer;
	border-bottom: ${(props) => (props.selected ? '2px solid #888' : 'none')};
	width: 50%;
	display: flex;
	justify-content: center;
`

const TabContent = styled.div<any>`
	padding: 20px;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)} !important;
	background: ${({isDarkTheme, isActive}) => (isActive ? (isDarkTheme ? `${palette.gray_gradient}` : `${palette.blue_mirror}`) : isDarkTheme ? `${palette.dark}` : `${palette.white}`)} !important;
	& > div {
		color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)} !important;
		background: ${({isDarkTheme, isActive}) => (isActive ? (isDarkTheme ? `${palette.gray_gradient}` : `${palette.blue_mirror}`) : isDarkTheme ? `${palette.dark}` : `${palette.white}`)} !important;
	}
`

const StyledIcon = styled.div<any>`
	display: flex;
	align-items: center;
	gap: 10px;

	svg {
		width: 24px;
		height: 24px;
		fill: ${({isDarkTheme}) => (isDarkTheme ? `${palette.white}` : `#333`)} !important;
	}

	._90151c79 {
		font-size: 16px;
		font-weight: bold;
	}
`

const ChatAssistantWrapper = styled.div<any>`
	background-color: #f8f8f8;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	text-align: center;
	position: relative;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	background: ${({isDarkTheme, isActive}) => (isActive ? (isDarkTheme ? `${palette.gray_gradient}` : `${palette.blue_mirror}`) : isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
`

const ChatAssistantContent = styled.div<any>`
	height: 50%;
	align-items: start;
	padding: 0.5rem 1.2rem;
	border-radius: 4px;
	background-color: #ebf1ff;
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)} !important;
	background: ${({isDarkTheme, isActive}) => (isActive ? (isDarkTheme ? `${palette.gray_gradient}` : `${palette.blue_mirror}`) : isDarkTheme ? `${palette.dark}` : `#ebf1ff`)} !important;
`

const ChatToKnowMore = styled.div`
	display: block;
	line-height: 1.6rem;
	font-weight: 700;
`

const CloseToDealMore = styled.div`
	display: block;
	line-height: 1.6rem;
`

const ChatAssistantButtons = styled.div<any>`
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	background: ${({isDarkTheme, isActive}) => (isActive ? (isDarkTheme ? `${palette.gray_gradient}` : `${palette.blue_mirror}`) : isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
	& > button {
		color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
		background: ${({isDarkTheme, isActive}) => (isActive ? (isDarkTheme ? `${palette.gray_gradient}` : `${palette.blue_mirror}`) : isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
	}
`

const Button = styled.button`
	height: 2.6rem;
	color: #002f34;
	background-color: #fff;
	padding: 0 0.4rem;
	border: 0.2rem solid #002f34;
	border-radius: 4px;
	-webkit-box-shadow: 0 0 0 0.1rem rgba(0, 47, 52, 0.64);
	box-shadow: 0 0 0 0.1rem rgba(0, 47, 52, 0.64);
	font-weight: 700;
	cursor: pointer;
`

const PaneBody = styled.div<any>`
	background-color: #f5f5f5;
	padding: 20px;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	background: ${({isDarkTheme, isActive}) => (isActive ? (isDarkTheme ? `${palette.gray_gradient}` : `${palette.blue_mirror}`) : isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
`

const MessagesContainer = styled.div`
	overflow-anchor: none;
	height: 22rem;
	overflow-y: scroll;
`

const AvatarHeader = styled.div`
	padding: 10px;
	border-bottom: 1px solid #ccc;
	width: 100%;
`

const Headline = styled.div`
	color: rgb(92, 107, 115);
	font-size: 1.5rem;
	margin-left: 10px;
	text-align: center;
`

const DateSeparator = styled.div`
	margin: 10px 0;
	text-align: center;
	color: #777;
`

const Message = styled.div`
	margin: 10px;
	padding: 10px;
`

const MessageContent = styled.div`
	display: flex;
`

const MessageMeta = styled.div`
	margin-right: 10px;
	color: #777;
`

const MessageBubble = styled.div`
	border: 1px solid #ddd;
	padding: 10px;
	background-color: #f9f9f9;
`

const Footerbar = styled.div<any>`
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)} !important;
	background: ${({isDarkTheme, isActive}) => (isActive ? (isDarkTheme ? `${palette.gray_gradient}` : `${palette.blue_mirror}`) : isDarkTheme ? `${palette.dark}` : `${palette.white}`)} !important;
	&::after {
		color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)} !important;
		background: ${({isDarkTheme, isActive}) => (isActive ? (isDarkTheme ? `${palette.gray_gradient}` : `${palette.blue_mirror}`) : isDarkTheme ? `${palette.dark}` : `${palette.white}`)} !important;
	}
`

const MessageEditor = styled.div<any>`
	display: flex;
	align-items: center;
	margin-top: 0.5rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)} !important;
	background: ${({isDarkTheme, isActive}) => (isActive ? (isDarkTheme ? `${palette.gray_gradient}` : `${palette.blue_mirror}`) : isDarkTheme ? `${palette.dark}` : `${palette.white}`)} !important;
	& > text-area {
		color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)} !important;
		background: ${({isDarkTheme, isActive}) => (isActive ? (isDarkTheme ? `${palette.gray_gradient}` : `${palette.blue_mirror}`) : isDarkTheme ? `${palette.dark}` : `${palette.white}`)} !important;
	}
`

const MessageEditorInputs = styled.div`
	margin-right: 10px;
`

const FileButton = styled.button<any>`
	background-color: transparent;
	border: none;
	cursor: pointer;
	margin-right: 10px;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.gray)} !important;
`

const EditorText = styled.div`
	flex-grow: 1;
	textarea {
		width: 100%;
		padding: 1px 13px;
		border: 1px solid #ccc;
		border-radius: 10rem;
		display: block;
		margin: 0 auto;

		box-sizing: border-box;
		vertical-align: middle;
	}
`
const CustomTextArea = styled.textarea<any>`
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)} !important;
	background: ${({isDarkTheme, isActive}) => (isActive ? (isDarkTheme ? `${palette.gray_gradient}` : `${palette.blue_mirror}`) : isDarkTheme ? `${palette.dark}` : `${palette.white}`)} !important;
	&:outline {
		border-color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.white}` : `${palette.gray}`)} !important;
	}
	&:focus-visible {
		border-color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.white}` : `${palette.gray}`)} !important;
		outline: white;
	}
	&:focus {
		border-color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.white}` : `${palette.gray}`)} !important;
	}
`

const SendButton = styled.button<any>`
	color: #fff;
	border: none;
	padding: 10px;
	border-radius: 4px;
	cursor: pointer;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)} !important;
	background: ${({isDarkTheme, isActive}) => (isActive ? (isDarkTheme ? `${palette.gray_gradient}` : `${palette.blue_mirror}`) : isDarkTheme ? `${palette.dark}` : `${palette.white}`)} !important;
`

const ChatItemContainer = styled.div<any>`
	display: flex;
	align-items: center;
	padding: 12px;
	border-bottom: 1px solid #ddd;
`

const AvatarPresence = styled.div`
	margin-right: 12px;
`

const Avatar = styled.img`
	border-radius: 50%;
`

const Presence = styled.span`
	display: inline-block;
	width: 10px;
	height: 10px;
	background-color: green;
	border-radius: 50%;
`

const ItemBody = styled.div`
	flex-grow: 1;
	margin-right: 12px;
`

const ItemRow = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 6px;
`

const ItemTitle = styled.div`
	font-weight: bold;
	font-size: 16px;
`

const Meta = styled.time`
	font-size: 12px;
	color: #666;
`

const ItemText = styled.div`
	span {
		font-weight: bold;
	}
`

const ItemActions = styled.div`
	margin-left: auto;
`

const CustomrName = styled.div<any>`
	${media.xs`
	display: none;
    `};
	${media.sm`
	display: none;
    `};
	${media.md`
	display: flex;
    `};
	${media.lg`
	display: flex;
    `};
`

const NoChatContent = styled.div<any>`
	border-radius: 0.3rem;
	padding: 0.5rem 0.8rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	justify-content: center;
	font-size: 1rem;
	cursor: pointer;
`

const SmsIconNoChat = styled(BsChat)<any>`
	cursor: pointer;
	z-index: 1;
	padding: 0.1rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 4rem;
	margin: 1rem 1.5rem;
`

const UnBlock = styled.div<any>`
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.dark_gray : palette.black)};
	border-radius: 0.3rem;
	padding: 0.5rem 0.8rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	justify-content: center;
	font-size: 1rem;
	font-weight: bold;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.white)};
	cursor: pointer;
`
const CustomBlockElement = styled.div<any>`
	position: fixed;
	bottom: 4.56rem;
	z-index: ${({isModel}) => (isModel ? 1 : 9999)};
	width: 62.5%;
	display: flex;
	padding: 10px;
	background: black;
	justify-content: space-between;
	align-items: center;
	color: white;
	${media.xs`
	width: 100%;
    `};
	${media.sm`
	 width: 100%;
    `};
	${media.md`
	 width: 56.2%;
    `};
	${media.lg`
	 width: 56.2%;
    `};
`
const AdImage = styled.img`
	max-width: 3rem;
	max-height: 3rem;
	margin-right: 10px;
`

const AdDetails = styled.div`
	flex: 1;
`

const ViewButton = styled.button`
	background-color: ${palette.red};
	color: #fff;
	border: none;
	padding: 7px 20px;
	font-size: 16px;
	cursor: pointer;
	border-radius: 5px;
	height: 2.5rem;
`

const CustomCol = styled(Col)<any>`
	width: 100%;
	background-color: #fff;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	border-bottom: 1px solid rgba(0, 47, 52, 0.2);
	display: flex;
	align-items: center;
	padding: 0.3rem 0.8rem;
	justify-content: space-between;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	background: ${({isDarkTheme, isActive}) => (isActive ? (isDarkTheme ? `${palette.gray_gradient}` : `${palette.blue_mirror}`) : isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
`
const Main = styled(Container)`
	padding-right: 0rem;
	padding-left: 0rem;
	${media.sm`
		padding-right:0.938rem;
		padding-left: 0.938rem;
	`}
`

const ChatWrapper = styled.div`
	display: flex;
	flex-direction: row;
	@media screen and (min-width: 0px) and (max-width: 767px) {
		flex-direction: column;
	}
`

const ChatList = styled.div`
	width: 30rem;
	border: 1px solid lightgray;
	border-top: none;
	@media screen and (min-width: 0px) and (max-width: 767px) {
		width: 100%;
	}
`

const CallIcons = styled(MdCall)<any>`
	cursor: pointer;
	z-index: 1;
	padding: 0.1rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.5rem;
`

const SmsIcon = styled(FaSms)<any>`
	cursor: pointer;
	z-index: 1;
	padding: 0.1rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.5rem;
	margin: 0px 1.5rem;
	${media.xs`
	margin: 0px 0.5rem;
    `};
	${media.sm`
	margin: 0px 0.5rem;
    `};
	${media.md`
	margin: 0px 1.5rem;
    `};
	${media.lg`
	margin: 0px 1.5rem;
    `};
`

const FlagIcon = styled(BsFlag)<any>`
	cursor: pointer;
	z-index: 1;
	padding: 0.1rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.5rem;
	margin: 0px 1.5rem;
	${media.xs`
	margin: 0px 0.5rem;
    `};
	${media.sm`
	margin: 0px 0.5rem;
    `};
	${media.md`
	margin: 0px 1.5rem;
    `};
	${media.lg`
	margin: 0px 1.5rem;
    `};
`
const VerticleDotIcon = styled(IoEllipsisVerticalSharp)<any>`
	cursor: pointer;
	z-index: 1;
	padding: 0.1rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.5rem;
	margin: 0px 1rem;
	${media.xs`
	margin: 0px 0rem;
    `};
	${media.sm`
	margin: 0px 0rem;
    `};
	${media.md`
	margin: 0px 1rem;
    `};
	${media.lg`
	margin: 0px 1rem;
    `};
`
const CloseIcon = styled(BsXLg)<any>`
	cursor: pointer;
	z-index: 1;
	padding: 0.1rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.5rem;
	margin: 0px 1rem;
`

const ChatWindow = styled.div<any>`
	width: calc(100% - 19rem);
	overflow: hidden;
	border: 1px solid lightgray;
	border-left: none;
	border-top: none;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	background: ${({isDarkTheme, isActive}) => (isActive ? (isDarkTheme ? `${palette.gray_gradient}` : `${palette.blue_mirror}`) : isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
	@media screen and (min-width: 0px) and (max-width: 767px) {
		width: 100%;
	}
`

const DropMenu = styled.div<any>`
	position: relative;
	&:hover #dropdown {
		display: block;
	}
`

const Drop = styled.div<any>`
	display: none;
	position: absolute;
	min-width: 12rem;
	z-index: 1;
	right: 1.5rem;
	top: 1.3rem;
	border-radius: 0.2rem;
	background: white;
	border: 1px solid lightgray;
`

const DropList = styled.div<any>`
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.off_white}` : `${palette.off_dark}`)};
	border-radius: 0.2rem;
	padding: 0.5rem 1rem;
	text-decoration: none;
	display: block;
	text-align: left;
	margin-bottom: 0.1rem;
	font-weight: 500;
	cursor: pointer;
	background-color: ${palette.off_black};
	background: ${({isDarkTheme, isActive}) => (isActive ? (isDarkTheme ? `${palette.gray_gradient}` : `${palette.blue_mirror}`) : isDarkTheme ? `${palette.dark}` : `${palette.white}`)};

	& p {
		background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.gray_gradient}` : `${palette.gray_gradient}`)};
		color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.off_black}` : `${palette.off_black}`)};
		cursor: pointer;
	}

	&:hover {
		background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.gray_gradient}` : `${palette.gray_300}`)};
		color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.off_black}` : `${palette.off_black}`)};
		cursor: pointer;
	}
	&:hover p {
		color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.off_black}` : `${palette.blue}`)};
		cursor: pointer;
	}
`

export default WeavyChat
